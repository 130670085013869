<template>
  <v-container class="shop-container ml-6">
    <!--Welcome-->
    <v-row
      class="mb-8"
      no-gutters
    >
      <v-col
        cols="8"
        class="pr-10"
      >
        <h1 class="fw-30-600 mb-3">
          Innovation Shop
        </h1>
        <span class="fw-14-500 gray-text">
          The Innovation Shop is your one-stop-shop to boost your KICKBOX Program
          in your organization. Whether you need a BlackBox ideation workshop,
          are looking for Coaches or want to supply your Kickboxers with
          our fun and rready-to-wear Merch, we’ve got you covered.
        </span>
      </v-col>
    </v-row>
    <!--Tags-->
    <v-row
      class="nav-container"
      align-content="center"
      justify="space-between"
      no-gutters
    >
      <v-col cols="8">
        <v-tabs
          v-model="tagIndex"
          height="40"
        >
          <v-tabs-slider color="primary" />
          <v-tab
            v-for="tag in allTags"
            :key="tag.key"
          >
            {{ tag.title }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model.trim="search"
          dense
          height="40"
          width="240"
          hide-details
          outlined
          placeholder="Search"
        />
      </v-col>
    </v-row>
    <div
      v-if="displayedServices.length"
      class="d-flex flex-column justify-center px-4 mt-10"
    >
      <v-row class="flex-wrap">
        <service-card
          v-for="service in displayedServices"
          :key="service.id"
          class="mr-10"
          :service="service"
        />
      </v-row>
    </div>
    <div
      v-if="!displayedServices.length"
      class="d-flex justify-center mt-16"
    >
      <h2>no results</h2>
    </div>
  </v-container>
</template>

<script>
import { InnoHubService } from '@kickbox/common-admin';
import { TAGS } from '@kickbox/common-util/constants/inno-hub';
import ServiceCard from '@/components/innovationShop/cards/ServiceCard';

export default {
  name: 'Overview',
  components: {
    ServiceCard
  },
  props: {
    index: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      services: [],
      tags: TAGS,
      tagIndex: 0,
      search: '',
    };
  },
  computed: {
    displayedServices() {
      return this.filterElements(this.services);
    },
    allTags() {
      return [{ key: 'all', title: 'ALL PRODUCTS' }, ...TAGS];
    }
  },
  async created() {
    const innoHubContent = await InnoHubService.getInnoHubContent();
    this.$store.commit('setInnoHubContent', innoHubContent);
    const { services } = this.$store.getters;
    this.services = this.$lodash.shuffle(services);
    if (this.index) {
      this.tagIndex = typeof this.index === 'string' ? parseInt(this.index, 10) : this.index;
    }
  },
  methods: {
    // This function is used to set Computed Variables "displayedServices"
    // Props: elements (the elements to be filtered)
    // Returns: Array of displayed elements
    filterElements(elements) {
      // Prepare filter values
      const search = this.search?.toLowerCase() || '';
      const selectedTag = this.allTags[this.tagIndex];

      // Return matching elements
      return elements.filter((element) => {
        // Filter by search string
        if (!element.title.toLowerCase()
          .includes(search)) {
          return false;
        }
        // Filter by tags
        if (selectedTag.key === 'all') return true;
        return element.tags.some((tag) => selectedTag && selectedTag.title.includes(tag.title));
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shop-container {
  max-width: 1185px;
  .v-text-field {
    width: 240px;
  }
}
::v-deep .v-tab {
  font-size: 13px;
  font-weight: 500;
  &.v-tab--active {
    color: #333333;
    font-weight: 700;
  }
}
</style>
