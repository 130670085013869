<template>
  <v-card
    height="320px"
    max-width="310px"
    rounded="lg"
    class="mb-7 d-flex flex-column justify-space-between"
    :to="{ name: 'ServiceDetailPage', params: { id: service.id } }"
  >
    <div>
      <img
        :src="service.cardImage"
        width="310"
        height="150"
        alt="service"
      >
      <div class="px-2">
        <v-card-title class="fw-18-600 pb-2">
          {{ service.title }}
        </v-card-title>
        <v-card-text class="subtitle fw-13-400 pb-0">
          {{ summary }}
        </v-card-text>
      </div>
    </div>
    <v-card-actions class="d-flex justify-end fw-16-600 pt-0 pb-3 pr-4">
      {{ service.price }}
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ServiceCard',
  props: {
    service: {
      required: true,
      type: Object
    }
  },
  computed: {
    summary() {
      const { summary } = this.service;
      return summary.length > 100 ? summary.slice(0, 100).trim().concat('...') : summary;
    }
  }
};
</script>
<style scoped>
.v-card__title {
  word-break: normal;
  line-height: 25px;
  padding-top: 0.5rem;
}
.subtitle {
  line-height: 16px;
}
</style>
